<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <h4>All Users</h4>
            <div class="card-header-action">
              <div class="dropdown">
                <a
                  href="#"
                  class="dropdown-toggle btn btn-primary"
                  data-toggle="dropdown"
                  >Filter</a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveEmployees('all')"
                    ><i class="fas fa-briefcase"></i> All Users</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveEmployees('employee')"
                    ><i class="fas fa-briefcase"></i> Employees</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveEmployees('admin')"
                    ><i class="far fa-user"></i> Admin</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveEmployees('agent')"
                    ><i class="far fa-user"></i> Agent</a>
                  >
                </div>
              </div>
            </div>
            <router-link
              to="/admin/users/create"
              class="btn btn-icon icon-left btn-primary"
              style="margin-left: 15px"
              ><i class="far fa-plus"></i> Create Users</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Category</th>
                    <th>Account Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="users.length > 0">

                  <tr v-for="(user, index) in users" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ user.name }} <br>
                      <small>
                        <span class="badge badge-success" v-if="user.loggedIn == 1">
                          Online
                        </span>
                        <span class="badge badge-danger" v-else>
                          Offline
                        </span>
                      </small>
                    </td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td>
                      <div class="badge badge-success" v-if="user.category == 'admin'">
                        Admin
                      </div>
                      <div class="badge badge-warning" v-else-if="user.category == 'agent'">
                        Agent
                      </div>
                      <div class="badge badge-info" v-else>
                        Employee
                      </div>
                    </td>
                    <td>
                      <div class="badge badge-success" v-if="user.status == 'active'">
                        Active
                      </div>
                      <div class="badge badge-danger" v-else>
                        Inactive
                      </div>
                    </td>
                    <td class="d-flex justify-content-space-around align-items-center">
                      <a
                        class="btn btn-sm text-info"
                        data-toggle="tooltip"
                        title="Edit User"
                        @click.prevent="editUser(user.id)"
                        ><i class="fas fa-pen"></i
                      ></a>
                      <a
                        class="btn btn-sm text-danger"
                        data-toggle="tooltip"
                        title="Delete"
                        @click.prevent="deleteUser(user.id)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6">No record found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h6>
                <strong>Total Users:</strong> {{ users.length }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GeneralHelpers from "@/functions/actions/GeneralRequest";
import SettingsHelpers from "@/functions/actions/SettingsRequest";
import { mapState } from "vuex";
export default {
  name: "Users",
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.retrieveEmployees(this.filter);
  },
  data() {
    return {
      users: [],
      filter: 'all'
    };
  },
  methods: {
    async retrieveEmployees(type) {
      const response = await GeneralHelpers.getUsersByType(type);
      if (response.status) {
        this.users = response.users;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async deleteUser(userId) {
      this.$confirm("Are you sure?").then(async () => {
        const response = await SettingsHelpers.deleteUser(userId)
        if (response.status) {
          this.users = this.users.filter(user => user.id != userId)
          this.showAlert('Success', 'User deleted successfully', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    editUser(userId) {
      this.$router.push({
        name: "Edit User",
        params: {
          userId
        }
      })
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style scoped>
</style>